import './plugins';
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import $ from 'jquery';
import simpleParallax from 'simple-parallax-js';
// Needed for hot module reloading in webpack
if (module.hot) {
  module.hot.accept();
}

import tippy from 'tippy.js/dist/tippy.esm'


function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function addBodyScrolled() {
  const scrollTop = $('body,html').scrollTop();
  if (scrollTop > 50) {
    $('body').addClass('scrolled');
  } else {
    $('body').removeClass('scrolled');
  }

  if (scrollTop > 90) {
    $('body').addClass('nav-scrolled');
  } else {
    $('body').removeClass('nav-scrolled');
  }
}

KBF.registerInitCallback('always', function () {

  tippy('.tippy', {
    arrow: true,
    maxWidth: '400px',
    trigger: 'click',
    hideOnClick: true,
    content: function (reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    }
  });

  $(document).ready(function () {
    let timeout;
    $(window).on('scroll', function () {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function () {
        addBodyScrolled();
      }, 1);
    })
    $(window).trigger('scroll')
  })

  const images = document.querySelectorAll('.parallax-image');
  new simpleParallax(images, {
    scale: 1.1,
  })

  $('body').on('click', '[data-gallery]', function (e) {
    e.stopPropagation();
    e.preventDefault();
    var $this = $(this);
    var id = $this.data('gallery');
    var index = $this.data('index');

    var pswpElement = $('.pswp')[0];
    var options = {
      galleryUID: id,
      bgOpacity: 0.85,
      index: index,
      shareEl: false,
      history: false
    };
    var referenceItems = window[id];
    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, referenceItems, options);
    gallery.init();
  });

  // $('.mainnav a,.footernav-main a').on('click', function (e) {
  //   var is_root = location.pathname == "/";
  //   if (is_root) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     var href = $(this).attr('href');
  //     var hash = href.substring(href.indexOf("#") + 1);
  //     var $target = $('#' + hash);
  //     if (!$target || $target.length < 1) return;
  //     $('html, body').stop().animate({
  //       'scrollTop': $target.offset().top - 120
  //     }, 900, 'swing');
  //     // window.location.hash = hash;
  //   }
  // });
  //
  // $('.menu--mob a').on('click', function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   var href = $(this).attr('href');
  //   var hash = href.substring(href.indexOf("#") + 1);
  //   var $target = $('#' + hash);
  //   if (!$target || $target.length < 1) return;
  //   $('html, body').stop().animate({
  //     'scrollTop': $target.offset().top - 120
  //   }, 900, 'swing');
  //   $('.menu-toggle--close-menu').trigger('click');
  // });

  $('.menu-toggle').on('click', function () {
    if ($('html').scrollTop() < 50) {
      $('body').toggleClass('scrolled');
    }
  })


  if (window.location.hash) scroll(0, 0);
// void some browsers issue
  if (window.location.hash) {

    if ($(window.location.hash).length < 1) {
      return;
    }

    // smooth scroll to the anchor id
    $('html, body').animate({
      scrollTop: $(window.location.hash).offset().top - 100 + 'px'
    }, 1000, 'swing');
  }

  $(document).foundation();

  $('.wysiywg .text-container img').wrap('<div class="image-filter-wrap"></div>');

  $('.reveal').on('open.zf.reveal', function (el) {
    var $el = $(el.currentTarget);
    var $img = $el.find('.cell img').height();
    $el.height($img);
    var $content = $el.find('.team-item');
    var styles = getComputedStyle($content[0]);
    if (styles['-webkit-overflow-scrolling'] !== 'touch') {
      $content.overlayScrollbars({});
    }
  })


  $('iframe').on('mousewheel DOMMouseScroll', function (e) {
    if (typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
      if (e.originalEvent.detail > 0) {
        console.log('Down');
      } else if (e.originalEvent.detail < 0) {
        console.log('Up');
      }
    } else if (typeof e.originalEvent.wheelDelta == 'number') {
      if (e.originalEvent.wheelDelta < 0) {
        console.log('Down');
      } else if (e.originalEvent.wheelDelta > 0) {
        console.log('Up');
      }
    }
  });

  $('.background-slider').slick({
    autoplay: true,
    autoplaySpeed:5000,
    fade: true,
    arrows:false,
    dots:false
  });

  var maxHeight = -1;
  $('.news-slider .slide').each(function() {
    if ($(this).height() > maxHeight) {
      maxHeight = $(this).height();
    }
  });
  $('.news-slider .slide').each(function() {
    if ($(this).height() < maxHeight) {
      $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
    }
  });

  $('.news-slider').slick({
    autoplay: true,
    autoplaySpeed:3000,
    arrows:false,
    adaptiveHeight: true,
    pauseOnHover:false,
    verticalSwiping: true,
    dots:false,
    vertical:true
  });


  // var $map = $('.map-wrapper');
  // if ($map.length > 0) {
  //   new Map({
  //     el: $map.get(0)
  //   })
  // }

  $('.mainnav').on('show.zf.dropdownmenu', function () {
    $('body').addClass('dropdown-active');
  })

  $('.mainnav').on('hide.zf.dropdownmenu', function () {
    $('body').removeClass('dropdown-active');

  })


});
